<template>
    <v-container fluid justify-center>
      <v-row class="pt-14 px-10 mt-6">
        <v-col class="mx-auto">
          <v-row>
            <h1 style="font-family: Bebas Neue">Contact Me</h1>
          </v-row>
          <v-row class="py-8">
             <h3 style="font-family: Cormorant Garamond;">If you like what you see and would like to work together, I can be reached by email&nbsp;<a style="color: black;" href="mailto:hello@hiredarcey.com?subject=Let's work together!">here.</a></h3>
          </v-row>
          <v-row class="pt-8" style="font-family: Cormorant Garamond">
            <h3 style="font-family: Cormorant Garamond;">I would love to discuss full and part time job opportunities as well as individual projects that you may have in mind for me. Please reach out and we can discuss details.</h3>
          </v-row>
        </v-col>
      </v-row>
    </v-container>  
  </template>
    
  <script>
  export default {
    name: 'contactPage',  
    data: () => ({
        //
    }),
  }
  </script>
    
  <style scoped>

  </style>