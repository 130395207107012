<template>
    <v-container fluid justify-center>
        <div class="pt-14 mt-6 px-16">
            <h1 class="my-5" style="font-family: Bebas Neue;">404</h1>
            <h2 class="my-2" style="font-family: Bebas Neue;">No content found</h2>
            <p style="font-family: Cormorant Garamond; font-size: 18px;">Please stick to the rivers and the lakes that you're used to.</p>
        </div>
    </v-container>  
</template>
    
<script>
export default {
    title: 'Pull Requests and Personal Records | Darcey Grundy',
    name: 'notFound',  
    data: () => ({
        //
    }),
  }
</script>
    
<style scoped>
</style>