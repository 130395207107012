<template>
    <v-container fluid justify-center>
        <v-row class="pt-14 mt-6">
           <v-col v-if="this.singleBlog" xs="10" xl="8" lg="8" md="8" sm="8" style="margin-left: auto !important; margin-right: auto !important;">
                <v-row class="mx-auto">
                    <h1 style="font-family: Bebas Neue;">{{ singleBlog.title }}</h1>
                </v-row>
                <v-row class="pb-2 mx-auto">
                    <h2 style="font-family: Cormorant Garamond;">{{ singleBlog.blurb }}</h2>
                </v-row>
                <v-row class="mx-auto" style="width: 100%;">
                    <v-img :src="singleBlog.image"></v-img>
                </v-row>
                <v-row class="pt-2 text-subtitle-2 mx-auto">
                    <h3 style="font-family: Cormorant Garamond;">{{ singleBlog.photoCredit }}</h3>
                </v-row>
                <v-divider class="mt-5"></v-divider>
                <v-row class="pt-10 mx-auto" style="color: grey;">
                    <b class="pl-1" style="letter-spacing: 2.5px; font-family: Bebas Neue; font-size: 18px;">By {{ singleBlog.author.toUpperCase() }} - Published {{ formatDate(singleBlog.pubDate) }}</b>
                </v-row>
                <v-row class="pt-10 mx-auto">
                    <p v-for="content in contents" :key="content" style="font-family: Cormorant Garamond;" class="text-body-1">
                        {{ content }}
                    </p>
                </v-row>
                <v-row v-if="this.links" class="mx-auto pb-5">
                    <p>
                        <a style="color: black;" target="_blank" v-bind:href="`${singleBlog.articleLink}`">{{ singleBlog.articleName }}</a>
                    </p>
                </v-row>
                <v-divider class="mt-5"></v-divider>
                <v-row v-if="this.tags" class="py-10 mx-auto">
                    <h4 class="d-flex flex-row align-center" style="font-size: 16px;font-family: Bebas Neue; letter-spacing: 2.3px;">
                        Tags:
                        <v-btn 
                            v-for="tag in tags" 
                            color="red" 
                            class="rounded-card mx-4 font-weight-medium" 
                            :key="tag"
                            :to="'/blogsByTag/' + tag"
                        >
                            {{ tag }}
                        </v-btn>
                    </h4>
                </v-row>
            </v-col>
            <v-col v-else class="pt-14 mt-6 px-16">
                <v-row class="py-2">
                    Loading blog...
                </v-row>
            </v-col>
        </v-row>
    </v-container>  
  </template>
    
<script>
import axios from 'axios';
import titleMixin from '../mixins/titleMixin';
export default {
    metaInfo() {
        return {
            title: this.title,
        };
    },
    name: 'singleBlog',  
    mixins: [titleMixin],
    data: () => ({
        singleBlog: [],
        contents: [],
        tags: [],
        hostName: {
            portfolioServer: process.env.VUE_APP_PORTFOLIO_SERVER
        },
        links: null
    }),
    props: ['id'],
    mounted: async function() {
        let $vm = this;
        let reqId = { _id: $vm.id }
        await axios.get(`${this.hostName.portfolioServer}/api/blog`, { params: reqId })
        .then(response => {
            $vm.singleBlog = response.data[0]
            $vm.contents = response.data[0].content
            $vm.title = response.data[0].title
            $vm.tags = response.data[0].tags
            $vm.links = response.data[0].articleLink
            console.log($vm.links, response.data[0].articleLink)
        })
        .catch(function(error) {
            console.log(error) 
        })
    },
}
</script>
    
<style scoped>
.blogMargins {
    margin-left: auto;
    margin-right: auto;
}
</style>