<template>
    <v-container fluid>
        <v-flex>
            <v-row>
                <v-col cols="12" class="text-center">
                    <p class="mb-4 text-h4 font-weight-bold" style="font-family: Bebas Neue !important;">What is Pull Requests and Personal Records?</p>
                    <p class="text-body-1 font-weight-bold mb-10" style="font-family: Cormorant Garamond !important; font-size: 20px !important;">It's my own space in the tech community (re: Instagram) where I can celebrate two of the things that are crucial to my life: tech and fitness. Being a developer isn't about pushing out PR after PR and counting them for the sake of numbers on a board, but the continued efforts are cumulative. The same can be said for fitness. The two are interlinked for me as both are disciplines that have improved my life so much already.</p>
                    <p class="text-body-1 font-weight-bold mb-10" style="font-family: Cormorant Garamond !important; font-size: 20px !important;">You can follow me on Instagram to keep up with these efforts or you can view my feed below.</p>
                </v-col>
            </v-row>
            <v-row v-if="!isFetching" class="justify-center">
                <span v-for="post in instagramPosts" :key="post.id">
                    <v-col>
                        <v-hover v-slot="{ hover }">
                            <v-card :href="`${post.permalink}`" target="_blank">
                                <v-img 
                                    v-if="hover"   
                                    :src="post.media_url" 
                                    height="200px" 
                                    width="200px"
                                > 
                                    <div class="overlay">
                                        <p class="pa-4 text-left font-weight-bold" style="display: inline-block; opacity: 3;">
                                            {{ post.caption.slice(0, 75) + '...' }}
                                        </p>
                                        <p>
                                            <v-icon>mdi-heart</v-icon><v-icon>mdi-chat</v-icon>
                                        </p>
                                    </div> 
                                </v-img>
                                <v-img v-else :src="post.media_url" cover height="200px" width="200px" /> 
                            </v-card>
                        </v-hover> 
                    </v-col>
                </span>
            </v-row>
        </v-flex>
    </v-container>  
</template>
    
<script>
import axios from 'axios';
export default {
    name: 'prBlurb',   
    data: () => ({
        instagramPosts: [],
        hostName: {
            igToken: process.env.VUE_APP_IG_TOKEN
        },
        isFetching: true,
        transparent: 'rgba(255, 255, 255, 0)'
    }),
    mounted: async function() {
        const $vm = this;
        const fieldData = 'id,caption,media_url,timestamp,media_type,permalink';
        await axios.get(`https://graph.instagram.com/me/media?access_token=${this.hostName.igToken}&fields=${fieldData}`)
            .then(response => {
                $vm.instagramPosts = JSON.parse(JSON.stringify(response.data.data))
                $vm.instagramPosts = $vm.instagramPosts.slice(0, -7)
                $vm.isFetching = false;
            })
            .catch(function(error) {
                console.log(error)
            })
    }
}
</script>
    
<style scoped>
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
    
.overlay:hover {
    opacity: 1;
}

.overlay p {
    font-size: 16px;
}

.v-icon {
    color: white;
    padding-left: 5px;
    padding-right: 5px;
}
</style>