<template>
    <v-container fluid justify-center>
        <v-row class="px-10 pt-14 mt-6">
            <h1 style="font-family: Bebas Neue;">Blogs</h1>
            <h3 class="pt-4" style="font-family: Cormorant Garamond;">Keeping up with the wide world of software development and tech is important to me. In doing so, it's really hard to keep my opinions to myself so I decided to write about them. If you enjoy anything I've written please <a href="mailto:hello@hiredarcey.com?subject=About your last blog..." style="color: black;">reach out to me</a> so we can keep the discussion going.</h3>
        </v-row>
        <v-row v-if="!isFetching" class="pt-12">
            <v-col
                v-for="blog in blogs" 
                :key="blog.title"
                :elevation="0"  
                cols="md-6 xs-12 sm-12"
                class="d-flex"
            >            
                <v-col class="pt-0 text-center">
                    <router-link 
                        :to="'/blogs/' + blog.link"
                        style="text-decoration: none; color: inherit; font-family:Bebas Neue; font-size: 22px;"
                    >
                        <v-img :src=blog.image  height="260px" width="495px" style="margin-left: auto; margin-right: auto;"/>
                        <h3 class="pt-5">{{ blog.title }}</h3>
                        <h4 class="pt-2 font-weight-light" style="margin-left: auto; margin-right: auto; line-height: 0.95;">{{  blog.blurb.toUpperCase() }}</h4>
                        <h5 class="py-5" style="color: grey;">{{ formatDate(blog.pubDate)  }} • {{ blog.author }}</h5>                    </router-link>                
                </v-col>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col class="text-center pa-8" style="padding-top: 20px; padding-bottom: 20px;">
                Loading blogs...
            </v-col>
        </v-row>
    </v-container>  
  </template>
    
  <script>
  import axios from 'axios';
  import store from '../store';
  import titleMixin from '../mixins/titleMixin';

  export default {
    title: 'Pull Requests and Personal Records | Blogs',
    name: 'blogPage',  
    mixins: [titleMixin],
    data() {
        return {
            storedBlogs: store.state.blogs.allBlogs,
            blogs: [],
            isFetching: true,
            hostName: {
                portfolioServer: process.env.VUE_APP_PORTFOLIO_SERVER
            }
        }
    },
    methods: {
     //
    },
    mounted: async function() {
        let $vm = this;
        await axios.get(`${this.hostName.portfolioServer}/api/blogs`)
            .then(response => {
                store.commit("updateBlogs", response.data)
                //store.state.blogs.allBlogs = response.data;
                $vm.blogs = response.data
                $vm.isFetching = false;
            })
            .catch(function(error) {
                console.log(error)
            })
    }
  }
  </script>
    
  <style scoped>

  </style>