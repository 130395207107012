<template>
    <v-container fluid justify-center>
        <v-row class="pt-14 mt-6">
            <v-col v-if="this.singleProject" xs="10" xl="8" lg="8" md="8" sm="8" style="margin-left: auto !important; margin-right: auto !important;">
                <v-row class="mx-auto">
                    <h1 style="font-family: Bebas Neue;">{{ singleProject.title }}</h1>
                </v-row>
                <v-row class="pb-2 mx-auto" style="width: 100%;">
                    <v-img :src="singleProject.picture"></v-img>
                </v-row>
                <v-row class="mx-auto">
                    <p class="font-weight-bold" style="font-family: Cormorant Garamond;">{{ singleProject.projectDesc }}</p>
                </v-row>
                <v-row class="py-2 justify-center align-center" style="font-family: Bebas Neue;">
                    <v-btn
                        :href="`${singleProject.githubLink}`"
                        target="_blank"
                        elevation="0"
                        color="#fff"
                        size="medium"
                    >
                        <h3>GitHub Repository Link</h3>
                    </v-btn>
                    |
                    <v-btn
                        :href="`${singleProject.liveLink}`"
                        target="_blank"
                        elevation="0"
                        color="#fff"
                        size="medium"
                    >
                        <h3>Live Website Link</h3>
                    </v-btn>
                </v-row>
            </v-col>
        </v-row>
    </v-container>  
</template>
    
<script>
import axios from 'axios';
import store from '../store';
export default {
    title: 'Pull Requests and Personal Records | Portfolio',
    name: 'singleProject',  
    data: () => ({
        singleProject: [],
        hostName: {
            portfolioServer: process.env.VUE_APP_PORTFOLIO_SERVER
        }
    }),
    props: ['id'],
    methods: {
    },
    mounted: async function() {
        let $vm = this;
        let reqId = { _id: $vm.id }
        await axios.get(`${this.hostName.portfolioServer}/api/project`, { params: reqId })
            .then(response => {
                store.commit("updateSingleProject", response.data[0])
                $vm.singleProject = response.data[0]
            })
            .catch(function(error) {
                console.log(error)
        })
    }
}
</script>
    
<style scoped>
</style>