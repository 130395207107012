import Vue from 'vue';
import VueRouter from 'vue-router';
import HelloWorld from './components/HelloWorld'
//import LinkTree from './components/linkTree'
import ProjectPage from './components/projects'
import ContactPage from './components/contact.vue';
import BlogPage from './components/blogs.vue';
import SingleBlog from './components/singleBlog.vue';
//import prPage from './components/twoPRs.vue';
import SingleProject from './components/singleProject';
import notFound from './components/notFound.vue';
import blogTag from './components/blogTag.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'HelloWorld',
        component: HelloWorld
    },
    // {
    //     path: '/links',
    //     name: 'LinkTree',
    //     component: LinkTree
    // },
    {
        path: '/projects',
        name: 'ProjectPage',
        component: ProjectPage
    },
    {
        path: '/contactMe',
        name: 'ContactPage',
        component: ContactPage
    },
    {
        path: '/blogs',
        name: 'BlogPage',
        component: BlogPage
    },
    {
        path: '/blogs/:id',
        name: 'SingleBlog',
        component: SingleBlog,
        props: true
    },
    {
        path: '/projects/:id',
        name: 'SingleProject',
        component: SingleProject,
        props: true
    },
    {
        path: '/blogsByTag/:id',
        name: 'blogTag',
        component: blogTag,
        props: true
    },
    {
        path: "/:notFound",
        component: notFound
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router;