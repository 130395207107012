import Vue from 'vue';
import Vuex from 'vuex';


Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        blogs: {
            singleBlog: [],
            allBlogs: [],
        },
        projects: {
            singleProject: [],
            allProjects: []
        }
    },
    mutations: {
        updateBlogs(state, data) {
            state.blogs.allBlogs = data;
        },
        updateProjects(state, data) {
            state.projects.allProjects = data;
        },
        updateSingleBlog(state, data) {
            state.blogs.singleBlog = data;
        },
        updateSingleProject(state, data) {
            state.projects.singleProject = data;
        }
    }
})