import { render, staticRenderFns } from "./projects.vue?vue&type=template&id=ab79d72c&scoped=true"
import script from "./projects.vue?vue&type=script&lang=js"
export * from "./projects.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab79d72c",
  null
  
)

export default component.exports