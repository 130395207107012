<template>
    <v-container fluid justify-center>
        <v-row class="px-10 pt-14 mt-6">
            <h1 style="font-family: Bebas Neue;">Work</h1>
        </v-row>
        <v-row>
            <h3 class="px-10" style="font-family: Cormorant Garamond;">Here are some projects I've worked on. I am always working on adding to this page to stay sharp. You can view the repositories attached as well as a live link for each project.</h3>
        </v-row>
        <v-row v-if="!isFetching" class="py-12">
            <v-col
                v-for="project in projects"
                :key="project.title"
                cols="12"
                class="d-flex"
            >
                <v-col class="text-center pb-6">
                    <router-link :to="'/projects/' + project.link" style="text-decoration: none; color: inherit; font-family: Bebas Neue; font-size: 22px;">
                        <v-img :src=project.picture height="260px" width="600px" style="margin-left: auto; margin-right: auto;" />
                        <h3 class="pt-5">{{ project.title }}</h3>
                        <p class="font-weight-light" style="margin-left: auto; margin-right: auto; padding-top: 12px; line-height: 0.95;">{{ project.projectDesc.toUpperCase() }}</p>
                    </router-link>
                </v-col>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col class="text-center pa-8">
                Loading projects....
            </v-col>
        </v-row>
    </v-container>  
  </template>
    
  <script>
  import axios from 'axios';
  import store from '../store';
  export default {
    name: 'projectPage',  
    title: 'Pull Requests and Personal Records | Work',
    data() {
        return {
            storedProjects: store.state.projects.allProjects,
            projects: [],
            isFetching: true,
            hostName: {
                portfolioServer: process.env.VUE_APP_PORTFOLIO_SERVER
            }
        }
    },
    mounted: async function() {
        let $vm = this;
        await axios.get(`${this.hostName.portfolioServer}/api/projects`)
            .then(response => {
                store.state.projects.allProjects = response.data;
                $vm.projects = response.data
                $vm.isFetching = false;
            })
            .catch(function(error) {
                console.log(error)
            })
    }
  }
  </script>
    
  <style scoped>

  </style>