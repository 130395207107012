<template> 
    <v-container fluid justify-center>
        <v-row class="px-10 pt-14 mt-6">
            <h1 style="font-family: Bebas Neue;">Blogs by Tag: {{ id }}</h1>
        </v-row>
        <v-row v-if="!isFetching" class="pt-12">
            <v-col
            v-for="blog in blogs"
            :key="blog.title"
            cols="md-6 xs-12 sm-12"
            class="d-flex">
            <v-col class="pt-0 text-center">
                    <router-link 
                        :to="'/blogs/' + blog.link" 
                        style="text-decoration: none; color: inherit;"
                    >
                        <v-img :src=blog.image  height="260px" width="495px" style="margin-left: auto; margin-right: auto;"/>
                        <h3 class="pt-5" style="font-family: Bebas Neue;">{{ blog.title }}</h3>
                        <h4 class="pt-2 font-weight-light" style="margin-left: auto; margin-right: auto; line-height: 0.95;">{{  blog.blurb.toUpperCase() }}</h4>
                        <h5 class="py-5" style="color: grey; font-family: Bebas Neue; letter-spacing: 1.5px; font-size: 13px;">{{ formatDate(blog.pubDate)  }} • {{ blog.author }}</h5>                    </router-link>                
                </v-col>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col class="text-center pa-8">
                Loading blogs...
            </v-col>
        </v-row>
    </v-container>
</template>
    
<script>
import axios from 'axios';
import titleMixin from '../mixins/titleMixin';
export default {
    name: 'blogTag',
    mixins: [titleMixin],  
    data: () => ({
        blogs: [],
        isFetching: true,
        hostName: {
            portfolioServer: process.env.VUE_APP_PORTFOLIO_SERVER
        }
    }),
    props: ['id'],
    mounted: async function() {       
        let $vm = this;
        let reqTag = { tag: $vm.id }
        await axios.get(`${this.hostName.portfolioServer}/api/blogsByTag`, { params: reqTag })
        .then(response => {
            $vm.blogs = response.data;
            $vm.isFetching = false;
        })
        .catch(function(error) {
            console.log(error) 
        })
    },
}
</script>
    
<style scoped>
</style>