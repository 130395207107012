<template>
  <v-container fluid class="text-center pa-4 elevation-4 d-flex align-center justify-space-around">
    <div>
      <v-btn
        icon
        v-for="site in socialMedia"
        :key="site.site"
        :href="`${site.link}`"
        target="_blank"
      >
        <v-icon medium>
          {{  site.mdiIcon }}
        </v-icon>
      </v-btn>      
    </div>
    <div class="navFooterFont" style="font-family: Cormorant Garamond;">
      <v-icon>
        mdi-copyright
      </v-icon>
      2023 - Darcey Grundy
    </div>
  </v-container>  
</template>
    
<script>
export default {
  name: 'footerPage',  
  data: () => ({
    socialMedia: [
      {
        link: 'https://mastodon.social/@pullrequestsandpersonalrecords',
        mdiIcon: 'mdi-mastodon'
      },
      {
        link: 'http://instagram.com/pullrequestsandpersonalrecords',
        mdiIcon: 'mdi-instagram'
      },
      {
        link: 'https://www.linkedin.com/in/darcey-grundy/',
        mdiIcon: 'mdi-linkedin'
      },
      {
        link: 'https://github.com/grundydarcey',
        mdiIcon: 'mdi-github'
      }
    ]
  }),
}
</script>

<style scoped>
.navFooterFont {
  font-family: Cormorant Garamond;
}
</style>