<template>
  <v-app>
    <v-app-bar
      v-if="mobile"
      app
      :elevation="0"
      class="px-8 pb-5"
    >    
      <v-app-bar-title>
        <v-btn
          class="font-weight-black"
          href="/"
          elevation="0"
          :ripple="false"
          id="no-background-hover"
          style="font-family: Bebas Neue; letter-spacing: 1.2px; font-size: 22px;"
        >
          Pull Requests and Personal Records
        </v-btn>
      </v-app-bar-title>
      <v-spacer />
      <v-btn 
        v-for="navItem in navItems" 
        :key="navItem.title"
        :href="`${navItem.link}`"
        :elevation="0"
        class="font-weight-medium"
        :ripple="false"
        id="no-background-hover"
        style="font-family: Bebas Neue; font-size: 20px;"
      >
        {{ navItem.title }}
      </v-btn>
    </v-app-bar>
    <v-app-bar
      v-else
      app
      :elevation="0"
      class="px-0"
    >    
      <v-flex class="d-flex justify-space-between mt-4 mb-4 align-center">
      <v-app-bar-title>
        <v-btn
          class="font-weight-black ml-1"
          href="/pull+requests+and+personal+records"
          elevation="0"
          style="font-family: Bebas Neue; letter-spacing: 1.5px; font-size: 17px;"
          :ripple="false"
          id="no-background-hover"
        >
          Pull Requests<br />and Personal Records
        </v-btn>
      </v-app-bar-title>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      </v-flex>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      style="width: 55%;"
      temporary
      app
    > 
      <v-flex class="d-flex flex-column justify-space-between">
        <div class="d-flex flex-column">
        <v-btn 
          v-for="navItem in navItems" 
          :key="navItem.title"
          :href="`${navItem.link}`"
          :elevation="0"
          color="white"
          :ripple="false"
          id="no-background-hover"
          class="py-8 font-weight-black"
          style="font-family: Bebas Neue; letter-spacing: 2.25px; font-size: 15px;"
        >
          {{ navItem.title }}
        </v-btn>
      </div>
      <v-spacer />
      <div class="d-flex flex-column my-16">
        <v-btn
          class="font-weight-bold"
          :elevation="0"
          style="font-family: Bebas Neue; letter-spacing: 2px; font-size: 16px;" 
          :ripple="false"
          id="no-background-hover"
          color="white" 
        >
          PR and PR
        </v-btn>
      </div>
      </v-flex>
    </v-navigation-drawer>
    <router-view style="min-height: 100vh;"/>
    <footerPage />
  </v-app>
</template>

<script>
import footerPage from './components/footer.vue'
export default {
  name: 'App',
  components: {
    footerPage
  },
  data: () => ({
    drawer: false,
    navItems: [
      {
        title: 'Home',
        link: '/'
      },
      {
        title: 'Blog',
        link: '/blogs'
      },
      {
       title: 'Projects',
       link: '/projects'
      },
      { 
        title: 'Contact',
        link: '/contactMe'
      },
      // {
      //   title: 'Links',
      //   link: '/links'
      // }
    ]
  }),
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mdAndUp
    },
  }
};
</script>

<style>
.v-toolbar__content {
  padding: 2px;
}

.v-navigation-drawer {
  margin-top: 56px;
}

#no-background-hover::before {
  background-color: transparent !important;
  text-decoration: underline;
}

.v-toolbar {
  background-color: #fff;
}
</style>