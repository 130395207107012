import Vue from 'vue'
import App from './App.vue'
import router from './router';
import vuetify from './plugins/vuetify';
import titleMixin from './mixins/titleMixin';
import { inject } from '@vercel/analytics';
 
inject();

Vue.config.productionTip = false
Vue.mixin(titleMixin);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')