function getTitle(vm) {
    const { title } = vm.$options
    if (title) {
        return typeof title === 'function'
            ? title.call(vm)
            : title
    }
}

export default {
    created() {
        const title = getTitle(this)
        if (title) {
            document.title = title;
        }
    },
    methods: {
        formatDate(date) {
            const monthNum = new Date(date).getMonth()
            const yearNum = new Date(date).getFullYear();
            const dayNum = new Date(date).getDate() + 1;
        
            let textMonth;
            switch(monthNum) {
                case 0:
                    textMonth = "Jan."; 
                    break;
                case 1:
                    textMonth = "Feb."
                    break;
                case 2:
                    textMonth = "Mar.";
                    break; 
                case 3:
                    textMonth = "Apr."
                    break;
                case 4:
                    textMonth = "May"; 
                    break;
                case 5:
                    textMonth = "Jun."
                    break;
                case 6:
                    textMonth = "Jul.";
                    break; 
                case 7:
                    textMonth = "Aug."
                    break;
                case 8:
                    textMonth = "Sep."; 
                    break;
                case 9:
                    textMonth = "Oct."
                    break;
                case 10:
                    textMonth = "Nov."; 
                    break;
                case 11:
                    textMonth = "Dec."
                    break;
                default: 'none'
            }
            return textMonth + " " + dayNum + ", " + yearNum
        }
    }
}