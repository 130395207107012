import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        /* Coolor pallete choice colors */
        lightYellow: "#F6F4D2",
        vanilla: "#D4E09B",
        teaGreen: "#CBDFBD",
        atomicTangerine: "#F19C79",
        chestnut: "#A44A3F",

        /* boring preset colors */
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      }
    },
    options: { customProperties: true }
  }
});