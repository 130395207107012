<template>
  <v-container fluid style="margin-top: 12px;">
    <v-flex class="pt-16 px-8 justify-center" style="padding-bottom: 65px;">
      <v-row class="d-flex pt-16 justify-center align-center">
        <v-col cols="lg-8 xl-8">
          <v-row class="d-flex align-center justify-center">
            <v-col :cols="mobile ? 12 : 8">
              <v-fab-transition appear>
                <p class="font-weight-black mb-3 text-h2" style="font-family: Bebas Neue !important;">Hi!</p>
              </v-fab-transition>
              <p class="font-weight-black pt-3 text-h4" style="font-family: Bebas Neue !important;">
                My name is Darcey Grundy, I press buttons for a living.
              </p>
              <p class="pt-3 font-weight-black" style="font-family: Cormorant Garamond !important; font-size: 20px !important;">I'm a software developer currently based in Phoenix, AZ. I'm passionate about full-stack development and adding to my toolbelt constantly. I'm a proud career-switcher and bootcamper, two things I take pride in throughout my software career. I love working as a developer full time but I also have interest in developer relations and making sure that each new developer has that same feeling as the industry evolves.</p>
            </v-col>
            <v-col :cols="mobile ? 12 : 4" justify="center">
              <v-img src="../assets/smiles.jpg" class="mx-auto" style="border-radius: 300px; border: 3px solid #A44A3F" width="250px" />
            </v-col>
          </v-row>
          <v-row>
            <v-btn class="mx-auto my-9 pa-7 teaGreen" style="border-radius: 25px; border: 1px solid black; font-family: Bebas Neue; font-size: 19px;" size="x-large" @click="$vuetify.goTo('#pullRequests')">Get to know me</v-btn>
          </v-row>        
        </v-col>
      </v-row>
    </v-flex>
    <v-row>
    <TilesBlock height="450px"/>
    </v-row>
    <v-row>
      <prBlurb id="pullRequests" class="pa-10 my-7" />
    </v-row>
  </v-container>
</template>

<script>
import TilesBlock from './tiles.vue'
import prBlurb from './prBlurb.vue'
export default {
    title: 'Darcey Grundy | Home',
    name: 'HelloWorld',
    components: {
      TilesBlock,
      prBlurb
    },
    data: () => ({
      //
    }),
    computed: {
      mobile() {
        return this.$vuetify.breakpoint.mdAndDown
      },
    }
  }
</script>

<style scoped>


.perfectEgg {
  color: var(--v-perfectegg-base) !important;
}
</style>