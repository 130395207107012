<template>
  <v-layout>
    <v-flex class="d-flex" :class="mobile ? 'flex-column' : 'flex-row'">
      <v-hover 
        v-for="site in socialMedia" 
        :key="site.site" 
        width="25%"
        v-slot="{ hover }"
      >
        <v-card
          class="hoverClass text-center"
          :style="`background: ${site.color}`"
          :class="`${site.color}`"
          width="100%"
          :elevation="hover ? 10 : 0"
        >
          <v-btn 
            icon
            :href="`${site.link}`"
            target="_blank"
            class="pa-4 mt-7"
          >
            <v-icon x-large color="black">
              {{ site.mdiIcon }}
            </v-icon>
          </v-btn>
          <v-card-text class="font-weight-medium my-2" style="color: black; font-family: Bebas Neue; letter-spacing: 2.2px; font-size: 16px;" >
            {{ site.siteName.toUpperCase() }}
          </v-card-text>
        </v-card>
      </v-hover>
    </v-flex>
  </v-layout>
</template>
  
<script>
export default {
  name: 'TilesBlock',  
  data: () => ({
    socialMedia: [
      {
        siteName: 'Mastodon',
        link: 'https://mastodon.social/@pullrequestsandpersonalrecords',
        color: 'atomicTangerine',
        mdiIcon: 'mdi-mastodon'
      },
      {
        siteName: 'Instagram',
        link: 'http://instagram.com/pullrequestsandpersonalrecords',
        color: 'chestnut',
        mdiIcon: 'mdi-instagram'
      },
      {
        siteName: 'LinkedIn',
        link: 'https://www.linkedin.com/in/darcey-grundy/',
        color: 'teaGreen',
        mdiIcon: 'mdi-linkedin'
      },
      {
        siteName: 'GitHub',
        link: 'https://github.com/grundydarcey',
        color: 'vanilla',
        mdiIcon: 'mdi-github',
      },
    ],
  }),
  methods: {
    //
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs
    },
  }
}
</script>
  
<style scoped>
.v-card {
transition: opacity .4s ease-in-out;
}

.v-sheet.v-card {
  border-radius: 0px;
}

.v-card:not(.on-hover) {
  opacity: 0.7;
}

.atomicTangerine {
  background-color: var(--v-atomicTangerine-base) !important;
}

.teaGreen {
  color: var(--v-teagreen-base) !important;
}

.vanilla {
  color: var(--v-vanilla-base) !important;
}

.hoverClass {
  hover: on-hover
}
</style>